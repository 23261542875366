export const ar = {
  lang: "English",
  auth: {
    login: "تسجيل الدخول",
    signup: "إنشاء حساب",
    phone: "رقم الموبايل",
    name: "اسم المستخدم",
    email: "البريد الإلكتروني",
    accept: "الموافقة على",
    rememberMe: "تذكرني",
    termsAndConditions: "الشروط والأحكام",
    noAccount: "ليس لديك حساب؟",
    withAccount: "لديك حساب بالفعل؟",
    requiredName: "اسم المستخدم مطلوب",
    invalidName: "من فضلك ادخل اسم صحيح",
    requiredMobile: "رقم الموبايل مطلوب",
    invalidMobile: "من فضلك ادخل رقم موبايل صحيح",
    requiredEmail: "البريد الإلكتروني مطلوب",
    invalidEmail: "من فضلك , أدخل بريد إلكتروني صحيح",
    verifyOTP: "رمز التأكيد",
    resendCode: "إعادة إرسال الكود",
  },
  nav: {
    home: "الرئيسية",
    about: "عن الشركة",
    pendingOrders: "الطلبات المعلقة",
    balance: "الرصيد",
    buyers: "المشتري",
    signout: "تسجيل الخروج",
    contactUs: "تواصل معنا",
    terms: "الشروط والأحكام",
    profile: "الحساب الشخصي",
    bidders: "المزايدين",
    luckyHours: "ساعات الحظ",
    myGroups: "مجموعاتي",
    store: "المتجر",
    more: "المزيد",
    auctionRequests: "طلبات المزاد",
    buyAndSupply: "شراء وتوريد",
  },
  home: {
    greenMatter: "جرين ماتر",
    description: "شريكك في إعادة تدوير الألمنيوم بشكل مستدام",
    buyItem: "شراء هذا المنتج",
    exchangeItem: "استبدال هذا المنتج",
    available: "متاح في المتجر",
    pricePerKilo: "جنيه/كيلو",
    purchaseRequest: "طلب شراء",
    emptyItems:
      "تم بيع جميع خردة الألومنيوم. يرجى التحقق لاحقا من العناصر المضافة",
    itemModal: {
      available: "متاح",
      kilo: "كيلو",
      kilos: "كيلو",
      type: "حدد نوع الطلب",
      enterQuantity: "حدد الكمية",
      enterUnit: "حدد الوحده",
      totalPrice: "السعر الكلى",
      totalQuantity: "الكمية الكلية",
      payment: "حدد طريقة الدفع",
      quantity: "الكمية",
      unit: "الوحده",
      priceUnit: "جنيه",
      requiredType: "من فضلك حدد نوع الطلب",
      requiredQuantity: "من فضلك حدد الكمية",
      invalidQuantity: "من فضلك ادخل كمية صحيحة",
      requiredPayment: "من فضلك حدد طريقة الدفع",
      quantityError: "الكمية غير متاحة",
      taxes: "يضاف 2.5% ضريبة على السعر الكلي",
      walletNumber: "رقم محفظة الهاتف",
      requiredWallet: "من فضلك، ادخل رقم محفظة الهاتف",
      invalidWallet: "من فضلك، ادخل رقم محفظة هاتف صحيح",
      productPriceChanged: "مع الاسف, لقد تغير للتو سعر هذا المنتج و أصبح السعر",
      pressOk: "للأستمرار اضفط موافق",
      ok: "موافق"
    },
    exchangeModal: {
      heading: "استبدال المنتج",
      selectOrderType: "اختر نوع الطلب",
      selectBalance: "اختر من رصيدك",
      totalWeight: "الوزن الكلي",
      totalPrice: "السعر الكلي",
      enterTotalWeight: "ادخل الوزن",
      quantityLessThan1: "الحد الأدنى للشراء هو",
      notAvailableInStore: "لا يوجد مخزون متاح للكمية التي سيتم تبادلها",
      purchaseAmount: "قيمة الشراء ستكون",
      purchasePrice: "سعر الشراء",
      deduct: "اخصم من الرصيد",
      invalidQuantity: "ادخل كمية صحيحة",
      invalidQuantityNotEnoughBalance: "رصيدك غير كافي",
      weight: "الوزن",
      price: "السعر",
      exchange: "استبدال",
      exchangeItem: "استبدل المنتج",
      noBalance: "!ليس لديك رصيد صالح للاستبدال",
      kg: "كجم",
      le: "جنيه",
      totalPriceAfterCommissionDeduction: "السعر الكلي بعد خصم العمولة"
    },
  },
  about: {
    about: "عن الشركة",
    greenMatter: "جرين ماتر",
    firstSection: {
      1: `مرحباً بكم في "جرين ماتر"، المنصة الرائدة حيث يمكن للأفراد والشركات الدخول لشراء وبيع وتخزين خردة الألومنيوم بناءً على عمولة.`,
      2: `منذ إنشائها في عام ٢٠١٢، تطورت "جرين ماتر" إلى المنصة الرقمية الرائدة التي تيسر تجارة خردة الألومنيوم بين الأفراد والشركات والمستثمرين. نحن نعتقد أنه من خلال تسهيل هذه التبادلات، يمكننا أن نلعب دوراً حيوياً في تعزيز الاقتصاد الدائري وتقليل الأثر البيئي المرتبط بإنتاج الألومنيوم في مصر.`,
    },
    secondSection: {
      1: "نحن ملتزمون بتوفير تجربة سلسة وفعالة لجميع مستخدمينا. سواء كنت مورداً يبحث عن التخلص من خردة الألومنيوم الخاصة بك أو مشترياً يبحث عن مواد عالية الجودة، تقدم منصتنا واجهة سهلة الاستخدام تبسط العملية بأكملها. خيارات البحث والتصفية المتقدمة لدينا تسمح لك بالعثور على ما تحتاج إليه بالضبط، بينما يضمن نظام الدفع الآمن لدينا عملية تعامل سلسة وموثوقة.",
      2: `في "جرين ماتر"، تتجاوز جهودنا الحد من الأثر البيئي لإنتاج الألومنيوم؛ نحن ملتزمون بصياغة مستقبل أفضل للجميع.`,
    },
    joinSection: {
      title: "رسالتنا:",
      description: `تهدف "جرين ماتر" إلى تحويل صناعة خردة الألومنيوم بمجتمع من الأفراد ذوي الأفكار المتشابهة الذين ملتزمون باتخاذ خيارات مستدامة ودفع التحولات الإيجابية في البيئة والاقتصاد والمجتمع.`,
    },
    videoSection: {
      title: "رؤيتنا:",
      description: `أن نقود الاقتصاد الدائري في صناعة خردة الألومنيوم في منطقة الشرق الأوسط وشمال أفريقيا، من خلال الحد من الفاقد، وتعزيز الاستدامة، وتشجيع المشاركة الفعالة في الحفاظ على البيئة ونمو الاقتصاد.`,
    },
  },
  balance: {
    myBalance: "الرصيد",
    quantity: "الكمية:",
    noData: "ليس لديك طلبات",
    totalQuantity: "الكمية الكلية",
    totalPrice: "السعر الكلي",
    price: "سعر الشراء:",
    date: "تاريخ الشراء:",
    kg: "كجم",
    totalOfferPrice: "إجمالي سعر العرض",
    totalProfit: "إجمالي الربح",
    totalPurchasePrice: "إجمالي سعر الشراء",
    egp: "جنيه",
    profitPercentage: "نسبة الربح"
  },
  buyers: {
    title: "العروض",
    quantity: "الكمية:",
    price: "السعر",
    requestDate: "تاريخ الطلب:",
    noData: "ليس لديك عروض",
    weight: "الكمية:",
    moreOffers: "كل عروض",
  },
  pendingOrders: {
    title: "الطلبات المعلقة",
    pending: "الطلبات المعلقة",
    weight: "الكمية:",
    noData: "ليس لديك طلبات معلقة",
    paymentMethod: "طريقة الدفع:",
    allOrders: "الكل",
    cash: "كاش",
    credit: "بطاقة الائتمان",
    kg: "كجم",
    purchasePrice: "سعر الشراء:",
    totalPrice: "إجمالي السعر:",
    egp: "جنيه"
  },
  notificationModal: {
    enterQuantity: "حدد الكمية",
    requiredQuantity: "من فضلك حدد الكمية",
    correctQuantity: "من فضلك حدد كمية صحيحة",
    accept: "قبول",
    reject: "رفض",
    rejectMsg: "أنت على وشك رفض العرض!",
    acceptOffer: "قبول العرض",
    rejectOffer: "رفض العرض",
    profit: "قيمة الربح",
    profitPercentage: "نسبة الربح",
    commission: "قيمة العمولة",
    commissionPercentage: "نسبة العمولة",
    egp: "جنيه",
    priceAfterCommissionDeduction: "سعر البيع بعد خصم العمولة",
    sellItem: "بيع المنتج",
    purchasePrice: "سعر الشراء",
    chooseCollectionType: "من فضلك اختر طريقة تحصيل إجمالي المبلغ بعد خصم العمولة إما نقديا أو عن طريق استخدام المتجر"
  },
  profileModal: {
    noGroups: "لم يتم الانضمام إلى مجموعات",
    myGroups: "مجموعاتي"
  },
  bidders: {
    actualBudget: "الميزانية الكلية",
    availableBudget: "الميزانية المتاحة",
    empty: "الصفحة فارغة!",
    checkLater: "يرجى التحقق لاحقًا من إضافة مزادات!",
    activeAuctions: "المزادات المتاحة",
    allAuctions: "كل المزادات",
    minBidding: "الحد الأدنى للمزاد",
    start: "البداية:",
    started: "البداية:",
    am: "ص",
    pm: "م",
    lastPrice: "آخر سعر",
    auctionPrice: "سعر المزاد",
    endTime: "سينتهي بعد",
    viewDetails: "عرض التفاصيل",
    join: "إنضم",
    deniedAccess: "يجب عليك الإنضمام لمجموعه ما لكي تشارك في المزادات الخاصه بنا!",
    ok: "حسناً",
    backToAuctions: "العودة للمزادات",
    liveAuctions: "المزادات الحالية",
    upcomingAuctions: "المزادات القادمة",
    oldAuctions: "المزادات السابقه الخاصه بي",
    searchPlaceholder: "ابحث باسم المزاد أو تاريخ البداية..",
    noResults: "لم يتم العثور على نتائج!",
    auctionDetails: "تفاصيل المزاد",
    incrementValue: "معدل الزيادة للقفزه الواحده",
    allAuctionItems: "كل منتجات المزاد:",
    itemQuantity: "الكمية:",
    itemWeight: "الوزن:",
    seeAuctionGroups: "رؤية مجموعات المزاد",
    emptyChatMsg: "كن أول من يفوز بالمزاد",
    writeHere: "اكتب هنا",
    allGroups: "كل المجموعات",
    selectGroupToJoinAuction: "اختر إحدى مجموعاتك للانضمام إلى هذا المزاد!",
    groupName: "اسم المجموعة",
    joinAuctionRequiredGroupMsg: "يجب عليك اختيار المجموعة المراد الإنضمام معها للحصول على المزاد",
    supervisorJoinedSameAuctionWithDifferentGroup: "عفوا, أنت مشارك بالفعل في هذا المزاد بجروب آخر",
    chat: {
      you: "أنت",
      emptyState: "من فضلك , أدخل سعر المزاد",
      invalidPrice: "من فضلك , أدخل سعر صحيح للمزاد",
      insufficientBudget: "ميزانية مجموعتك لم تعد كافية للمتابعة، يمكنك زيادتها بعد موافقة الأعضاء",
      congratulations: "تهانينا!",
      winnerMsg: "عمل عظيم! أنتم الفائزون بالمزاد.",
      winnerOfAuction: "هو الفائز بالمزاد",
      reachedMarketPrice: "لقد وصل سعر المزايدة إلى سعر السوق"
    },
    maxQuantityPerUser: "أعلى كمية للفرد",
    participate: "مشاركة"
  },
  luckyHours: {
    luckyHours: "ساعات الحظ",
    noOffers: "لا توجد عروض متاحة في هذا الوقت. الرجاء المحاولة لاحقا!",
    luckyHoursItems: "عناصر ساعات الحظ",
    offer: "عرض"
  },
  storeItems: {
    description: "الوصف",
    enterQuantity: "ادخل الكمية",
    buy: "شراء",
    requiredQuantityMsg: "من فضلك, أدخل الكمية",
    notAvailableQuantityMsg: "الكمية غير متاحة ف المتجر",
    minQuantityMsg: "الحد الأدنى للشراء 1",
    totalPrice: "السعر الكلى",
    emptyState: "!لا يوجد حاليا أي منتجات متوفرة في المتجر. الرجاء المحاولة مرة أخرى لاحقا",
    confirmMsg: {
      1: "أنت على وشك دفع",
      pay: "و سيتم دفع",
      recieve: "و سيتم استلام",
      3: "نقديا عند الاستلام"
    },
    confirm: "تأكيد",
    backToHome: "عودة للرئيسية",
    exchange: "استبدال",
    selectPaymentMethod: "اختر وسيلة الدفع",
    paymentMethod: "وسيلة الدفع",
    feesAdded: "*2.5% ضرائب مضافة",
    purchaseAmount: "قيمة الشراء ستكون",
    totalPurchasePrice: "سعر الشراء",
    item: "منتج",
    exchangePriceDiffMsg: {
      recieve: "سوف يصلك",
      pay: "يجب عليك دفع",
      2: "قيمة الفرق في السعر وهي",
      3: "كاش عند الاستلام"
    }
  },
  groups: {
    groupBudget: "ميزانية المجموعة",
    groupMembers: "أعضاء المجموعة",
    you: "أنت",
    supervisor: "مشرف",
    increaseBudgetBtn: "طلب زيادة الميزانية",
    increaseBudget: {
      requiredIncrement: "من فضلك, أدخل قيمة الزياده لكل عضو داخل الجروب",
      invalidIncrement: "من فضلك, أدخل قيمة الزياده الصحيحة",
      increaseBudgetFor: "طلب زيادة ميزانية",
      incrementValuePerMember: "قيمة الزيادة لكل عضو",
      sendIncrementRequest: "إرسال طلب الزيادة",
      increaseBudgetPlaceholder: "أدخل قيمة الزيادة"
    },
    lastRequestState: "حالة آخر طلب",
    Pending: "قيد المراجعة",
    Accepted: "تمت الموافقة",
    Rejected: "تم الرفض",
    requestForMember: {
      requestedIncrementAmount: "قيمة الزيادة المطلوبة",
      confirmAccept: "هل أنت متأكد من أنك تريد قبول الطلب؟",
      confirmReject: "هل أنت متأكد من أنك تريد رفض الطلب؟",
      accept: "قبول",
      reject: "رفض",
      cancel: "إلغاء",
      egp: "جنيه"

    }
  },
  auctionRequests: {
    drawerTitle: "طلبات المزاد",
    noData: "ليس لديك طلبات حتى الآن!",
    maxQuantityPerUser: "الحد الأقصى للكمية للمستخدم",
    kg: "كجم",
    accept: "قبول",
    reject: "رفض",
    confirm: "تأكيد",
    quantity: "الكمية",
    enterQuantity: "أدخل الكمية التي تريد استخدامها في المزاد!",
    requiredQuantityMsg: "من فضلك, أدخل الكمية",
    invalidQuantityMsg: "من فضلك, أدخل كمية صحيحة",
    quantityLessThanZeroMsg: "أقل قيمة يجب أن تكون أكبر من الصفر",
    insufficientBalanceMsg: "رصيدك من هذا النوع غير كافي",
    maxQuantityPerUserMsg: "أعلى قيمة يجب أن تكون أقل من أو تساوي",
    selectPaymentMethod: "إختر طريقة الدفع",
    paymentMethod: "طريقة الدفع",
    auctionPrice: "سعر المزاد",
    auctionQuantity: "كمية المزاد",
    pay: "ادفع",
    requiredPaymentMethod: "من فضلك, اختر طريقة دفع",
    confirmReject: "هل أنت متأكد أنك تريد إلغاء هذا الطلب؟",
    noBalanceMsg: "نأسف لعدم وجود رصيد لديك من هذا النوع. يمكنك الدخول في المزاد للحصول على الرصيد منه.",
    request: "طلبات",
    myAuctions: "مزاداتي",
    auctionStatus: "حالة المزاد",
    participatedAmount: "كمية المشاركات"
  },
  buyAndSupply: {
    title: "شراء وتوريد",
    buy: "شراء",
    supply: "توريد",
    pageIsEmpty: "الصفحة فارغة!",
    checkItemsLater: "يرجى التحقق لاحقًا من العناصر المضافة",
    checkTypesLater: "يرجى التحقق لاحقًا من الأنواع المضافة",
    checkBalanceLater: "يرجى التحقق لاحقًا من رصيدك",
    availableInStore: "متاح في المخزن",
    lePerKg: "جنيه / كجم",
    note:"note in arabic",
    buyItem: "شراء المنتج",
    enterQuantityMsg: "أدخل الكمية التي تريد شرائها",
    quantity: "الكمية",
    paymentMethod: "طريقة الدفع",
    requiredPaymentMethod: "من فضلك اختر طريقة دفع",
    balance: "رصيد",
    yourBalanceFrom: "رصيدك من",
    kilo: "كيلو",
    egp: "جنيه",
    requiredQuantityMsg: "من فضلك أدخل الكمية",
    invalidQuantityMsg: "من فضلك أدخل كمية صحيحة",
    exceedingAvailableQuantityMsg: "من فضلك أدخل كمية صحيحة",
    totalPrice: "إجمالي السعر",
    wantedQuantity: "الكمية المطلوبة",
    durationToRecieve: "  أدخل الكمية و اختر مدة استلام أموالك",
    selectDuration: "اختر المدة لتلقي الأموال",
    next: "التالي",
    requiredDuration: "من فضلك, اختر مدة",
    kg: "كجم",
    after:"بعد",
    day:"يوم",
    days:"أيام",
    totalPriceBuy: "السعر الإجمالي",
    price: "السعر",
    pendingOrders: "الطلبات المعلقة",
    emptyPendingOrders: "ليس لديك طلبات معلقة!"
  },
  invoice: {
    invoice: "فاتورة",
    itemName: "اسم العنصر",
    itemType: "اسم النوع",
    paymentMethod: "طريقة الدفع",
    paymobFees: "رسوم باي موب",
    quantity: "الكمية",
    unitPrice: "سعر الوحدة",
    totalPrice: "السعر الكلي",
    back: "رجوع",
    proceedToCheckout: "مواصلة الشراء",
    buyItem: "تأكيد الشراء"
  }
};
