export const ROUTES = {
  GLOBAL: "/home",
  ABOUT: "/about",
  SIGNIN: "/",
  SIGNUP: "/signup",
  LOGOUT: "/logout",
  CART: "/cart",
  PAYMOB: "/paymob",
  EWALLET: "/e-wallet",
  POSTPAY: "/postpay",
  PRIVACYPOLICY: "/privacy-policy",
  BIDDERS: "/bidders",
  LUCKYHOURS:"/lucky-hours",
  STORE:"/store",
  // AUCTION:"/auction",
  // BUYANDSUPPLY:"/buy-and-supply",
  // BUY:"buy",
  // SUPPLY:"supply",
  NOT_FOUND: "*",
};
